* {
  box-sizing: border-box;
}

body {
  background-color: #f8f8f8;
  color: #0b1c2f;
  font-family: Heebo, Helvetica, Arial, Roboto, sans-serif;
  line-height: 1.25;
  margin: 0;
  padding: 0;
}

button {
  -webkit-appearance: none;
  border: 0;
  cursor: pointer;
  font-family: Heebo, Helvetica, Arial, Roboto, sans-serif;
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
  margin-top: 0;
}

h1,
h2 {
  font-family: Adamina, Georgia, Times, serif;
}

h3,
h4,
h5,
h6 {
  font-family: Heebo, Helvetica, Arial, Roboto, sans-serif;
}

h1 {
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 1.15;
}

h2 {
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 1.33;
}

h3 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.3;
}

h4 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

h5 {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.15;
}

a {
  color: #2860b6;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

p {
  margin-top: 0;
}

/* PublicSans */
@font-face {
  font-family: "PublicSans";
  src: url(./assets/fonts/PublicSans/PublicSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans-Regular";
  src: url(./assets/fonts/PublicSans/PublicSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans-Bold";
  src: url(./assets/fonts/PublicSans/PublicSans-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans-Italic";
  src: url(./assets/fonts/PublicSans/PublicSans-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans-SemiBold";
  src: url(./assets/fonts/PublicSans/PublicSans-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans-SemiBoldItalic";
  src: url(./assets/fonts/PublicSans/PublicSans-SemiBoldItalic.ttf)
    format("truetype");
}

/* SourceSerifPro */
@font-face {
  font-family: "SourceSerifPro";
  src: url(./assets/fonts/SourceSerifPro/SourceSerifPro-Regular.ttf)
    format("truetype");
}
@font-face {
  font-family: "SourceSerifPro-Regular";
  src: url(./assets/fonts/SourceSerifPro/SourceSerifPro-Regular.ttf)
    format("truetype");
}
@font-face {
  font-family: "SourceSerifPro-Bold";
  src: url(./assets/fonts/SourceSerifPro/SourceSerifPro-Bold.ttf)
    format("truetype");
}
@font-face {
  font-family: "SourceSerifPro-It";
  src: url(./assets/fonts/SourceSerifPro/SourceSerifPro-It.ttf)
    format("truetype");
}
@font-face {
  font-family: "SourceSerifPro-SemiBold";
  src: url(./assets/fonts/SourceSerifPro/SourceSerifPro-SemiBold.ttf)
    format("truetype");
}
@font-face {
  font-family: "SourceSerifPro-SemiBoldIt";
  src: url(./assets/fonts/SourceSerifPro/SourceSerifPro-SemiBoldIt.ttf)
    format("truetype");
}

/** adapted from https://codepen.io/adielhercules/pen/dJJGBQ */

.iphone-x {
  position: relative;
  width: 360px;
  height: 720px;
  background-color: #fff;
  border-radius: 40px;
  margin: 16px;
  box-shadow: 0px 0px 0px 10px #1f1f1f, 0px 0px 0px 12px #191919,
    0px 0px 0px 16px #111;
}

.iphone-x.blur {
  filter: blur(5px);
}

.iphone-x .home,
.iphone-x .notch {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.iphone-x .home,
.iphone-x .notch,
.iphone-x .speaker {
  position: absolute;
  display: block;
  color: transparent;
}

.iphone-x .home {
  bottom: 7px;
  width: 140px;
  height: 4px;
  background-color: #f2f2f2;
  border-radius: 10px;
}

.iphone-x .notch {
  top: 0px;
  width: 56%;
  height: 30px;
  background-color: #1f1f1f;
  border-radius: 0px 0px 40px 40px;
}

.iphone-x .speaker {
  top: 0px;
  left: 50%;
  transform: translate(-50%, 6px);
  height: 8px;
  width: 15%;
  background-color: #101010;
  border-radius: 8px;
  box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
}

.ant-form-item-label > label {
  font-weight: 600;
}

.full-width-wrapper {
  width: 100% !important;
}

.consent-option {
  border: 1px solid #e7e7e7 !important;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
}

.ant-form-item-row {
  padding-top: 8px;
  padding-bottom: 8px;
}
